import { twMerge } from 'tailwind-merge'

interface NavButtonProps {
  direction?: 'prev' | 'next'
  className?: string
  disabled?: boolean
  onClick?: () => void
}

export function NavButton({ direction = 'next', className, disabled, onClick }: NavButtonProps) {
  const buttonAction = direction === 'next' ? 'Go to next item' : 'Go to previous item'

  return (
    <button
      aria-label={buttonAction}
      aria-disabled={disabled}
      className={twMerge(
        'flex aspect-square h-6 w-6 cursor-pointer justify-center rounded-full opacity-100 duration-300 focus-visible:outline-none aria-disabled:pointer-events-none aria-disabled:cursor-default aria-disabled:opacity-25 sm:h-8 sm:w-8 lg:hover:scale-110 lg:focus-visible:scale-110 lg:aria-disabled:hover:scale-110 lg:aria-disabled:focus-visible:scale-100',
        direction === 'prev'
          ? 'drop-shadow-[0_-2px_4px_rgba(0,0,0,0.85)] rotate-180'
          : 'drop-shadow-[0_2px_4px_rgba(0,0,0,0.85)] rotate-0',
        className,
      )}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 stroke-white stroke-[3px] drop-shadow-2xl sm:h-8 sm:w-8 sm:stroke-2"
        viewBox="0 0 18 32"
        fill="none"
      >
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m1.798 1.14 14.057 14.058a1.097 1.097 0 0 1 0 1.6L1.798 30.855"
        />
      </svg>
    </button>
  )
}
