import { MouseEvent } from 'react'
import { twMerge } from 'tailwind-merge'

interface CarouselDotsProps {
  className?: string
  currentIndex: number
  length: number
  onClick: (index: number) => void
}

export function ShowcaseProgress({ className, currentIndex, onClick, length }: CarouselDotsProps) {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    const index = Number(e.currentTarget.dataset.slideIndex)
    if (!isNaN(index)) {
      onClick(index)
    }
  }

  return (
    <div className={className}>
      <div className="flex items-end justify-center gap-x-0.5 sm:gap-x-1 md:gap-x-2">
        {Array.from({ length }).map((_, i) => {
          const current = currentIndex === i

          return (
            <button
              key={i}
              data-slide-index={i}
              className={twMerge('flex h-8 flex-1 cursor-pointer items-end pb-5')}
              onClick={handleClick}
            >
              <div
                className={twMerge(
                  'h-1.5 flex-1 origin-bottom bg-neutral-50 drop-shadow transition duration-500',
                  current ? 'opacity-100 scale-y-100' : 'opacity-30 scale-y-50',
                )}
              />
            </button>
          )
        })}
      </div>
    </div>
  )
}
