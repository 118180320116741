// function to strip html tags from a string
//
// Usage:
// const str = '<p>hello world</p>'
// const stripped = stripHtml(str)
//       ^ 'hello world'
export function stripHtml(html: string) {
  return html.replace(/<[^>]*>?/gm, '')
}

// function to capture x number of words from a string
//
// Usage:
// const str = 'hello world'
// const words = getWords(str, 1)
//       ^ 'hello'
export function getWords(str: string, count: number) {
  return str.split(' ').slice(0, count).join(' ')
}

export function formatStringList(
  items: string[],
  options?: {
    sort?: boolean
    comma?: string
    and?: string
  },
): string {
  const { sort = true, comma = ', ', and = ' and ' } = options || {}
  if (sort) {
    items.sort()
  }

  if (items.length > 1) {
    const start = items.slice(0, -2)
    const end = items.slice(-2)
    const joined = end.join(and)
    items.splice(0, items.length, ...start, joined)
  }
  return items.join(comma)
}
