'use client'

import { MouseEvent } from 'react'
import { usePathname } from 'next/navigation'

import { ActionLink } from './ActionLink'
import { ButtonProps } from './Button'

type BackLinkProps = ButtonProps & {
  collapse?: boolean
  underline?: boolean
  historyBack?: boolean
}

export function BackLink({ onClick, collapse, underline, historyBack, ...props }: BackLinkProps) {
  const parentPathname = usePathname()?.split('/').slice(0, -1).join('/') || '/'
  const handleClickOverride =
    onClick || historyBack
      ? (e: MouseEvent<HTMLAnchorElement> & MouseEvent<HTMLButtonElement>) => {
          e.preventDefault()
          onClick?.(e)
          historyBack && history.back()
          return false
        }
      : undefined

  const href = handleClickOverride ? undefined : 'href' in props && props.href ? props.href : parentPathname

  return (
    <ActionLink
      collapse={collapse}
      underline={underline}
      arrow="left"
      {...props}
      href={href}
      onClick={handleClickOverride}
    >
      <span className="font-semibold">Back</span>
    </ActionLink>
  )
}
