'use client'

import { type PropsWithChildren } from 'react'
import { createActorContext } from '@xstate/react'
import { type EmblaCarouselType, type EmblaOptionsType } from 'embla-carousel'
import { assign, createMachine } from 'xstate'

const defaultContext = {
  index: 0,
  items: 0,
  options: { containScroll: false },
  api: undefined,
  standalone: false,
  dimmed: false,
} as const

const showcaseMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5SwBYHsDuBjAhrMAxAOJoAEALmbADYCWEYA2gAwC6ioADmrLebWgB2HEAA9EAWgDMATgDsAOgCsUgIwAOVVIAsq7cwBsUgEwAaEAE9JGqQuOq5B5jOPH1BpczkBfb+dSYuPgKAJKCOFj8AG6EAIKRtFE45GCkAdh4TGwi3Lz8QiLiCBLGcup2LjJK2nLMxmrV5lYIaor2BqpGBsbaSj3Gvv7oGcFhEdGEACK0ALZpw0FZ7EgguXwCwitFEqqqxgrq-cZK8nIy7s5NiNraCgZySu7ahzKqVXuDIOmLCvETBJMwONEslUt9MixllweOsCltrEoDAo3up1HVtFJnMZ7mZLIglBoFJi3KipA9mOoVJ9wcEADJoHAQWiCKCkXAAJzQAFd8NRSLEAAohAgAZTA5FIzPWOD5sHIoMhORh+U2oG2jiRj3sulUznUcik6iuCGMMluzyqqJk9zO6hk1IWmQU9MZzNZHO5vP5QtF4slgmlsvlKUYqihq2VG0KkmqigNBLk2iMMikqdUxs6th6HQ8Dmz5ykDsCTr+IP4LIIojloIUOAAZil2QAKTzMACUBBpYF+CSS5agipWaxV0eKhv2zE6N2cbwN2h6xtkSgUzGYNxkKY3ejNRZG3cBwL7bsr1ZStYbYGbxlXHa7CgPveSbsH0LyUfhxV2y9crjJOjORyLpSK4OFI1RGMwmI6L4fggIIaAMPAKxdkqb5wmqkiUswyJOD0vQ9EohFSMaEjzgcPRaIiqYEvUAywXeYy9mAqGwqqYiSPUSL6PUninOcHQZsB8ilLILxvDRu4-KWMQsSOH4SGi+ydOi+G9ERxo3MuW6ePc1RYoW9GOnSDJMiybI4JyPJgHygohLJ74YcUchvAoNyqASez3LovTGvcrkqbUxzvG8kklo+-b2eh7HFLotg1Lqq6QXoKiUoudR2I8BgdJO7ieEooXBA+0RPiykVsdsWjYXs6gWoaa7MIiSgab0IHxkmUGQdoMHeEAA */
    id: 'showcase',
    tsTypes: {} as import('./ShowcaseContext.typegen').Typegen0,
    predictableActionArguments: true,
    preserveActionOrder: true,
    schema: {
      context: {} as {
        index: number
        items: number
        options: EmblaOptionsType
        api: EmblaCarouselType | undefined
        standalone: boolean
        dimmed: boolean
      },
      events: {} as
        | { type: 'Activate showcase' }
        | { type: 'Dim showcase'; dimmed: boolean }
        | { type: 'Go to slide'; index: number }
        | { type: 'Set initial state'; api: EmblaCarouselType }
        | { type: 'Deactivate showcase' },
    },
    context: {
      ...defaultContext,
    },
    initial: 'Loading carousel API',
    states: {
      Inactive: {
        on: {
          'Activate showcase': 'Activating',

          'Dim showcase': {
            target: 'Inactive',
            internal: true,
            actions: 'Set dimmed',
          },
        },
      },

      Active: {
        on: {
          'Deactivate showcase': 'Deactivating',
        },

        invoke: [
          {
            src: 'Listen for escape key',
            id: 'escapeKeyListener',
          },
          {
            src: 'Listen for arrow keys',
            id: 'arrowKeyListener',
          },
        ],
      },

      'Loading carousel API': {
        on: {
          'Set initial state': [
            {
              target: 'Active',
              actions: 'Save API',
              cond: 'Always active',
            },
            {
              target: 'Inactive',
              actions: 'Save API',
            },
          ],
        },
      },

      Activating: {
        after: {
          '500': 'Active',
        },
      },

      Deactivating: {
        after: {
          '200': 'Inactive',
        },
      },
    },
    on: {
      'Go to slide': {
        actions: ['Set index', 'Scroll to index'],
      },
    },
  },
  {
    guards: {
      'Always active': ({ standalone }) => standalone,
    },
    actions: {
      'Save API': assign((_ctx, { api }) => ({ api, items: api?.slideNodes().length || 0 })),
      'Set index': assign((_ctx, { index }) => ({ index })),
      'Scroll to index': ({ api }, { index }) => api?.scrollTo(index),
      'Set dimmed': assign((_ctx, { dimmed }) => ({ dimmed })),
    },
    services: {
      'Listen for escape key': () => (send) => {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'Escape') {
            send('Deactivate showcase')
          }
        }

        document.addEventListener('keydown', handleKeyDown)

        return () => {
          document.removeEventListener('keydown', handleKeyDown)
        }
      },
      'Listen for arrow keys':
        ({ api }) =>
        (send) => {
          const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'ArrowLeft' && api?.canScrollPrev()) {
              send({ type: 'Go to slide', index: api?.selectedScrollSnap() - 1 })
            } else if (event.key === 'ArrowRight' && api?.canScrollNext()) {
              send({ type: 'Go to slide', index: api?.selectedScrollSnap() + 1 })
            }
          }

          document.addEventListener('keydown', handleKeyDown)

          return () => {
            document.removeEventListener('keydown', handleKeyDown)
          }
        },
    },
  },
)

export type ShowcaseMachine = typeof showcaseMachine

export const ShowcaseContext = createActorContext(showcaseMachine)

export function ShowcaseProvider({
  children,
  ...contextAsProps
}: PropsWithChildren<{ standalone?: boolean; dimmed?: boolean }>) {
  return (
    <ShowcaseContext.Provider options={{ context: { ...defaultContext, ...contextAsProps } }}>
      {children}
    </ShowcaseContext.Provider>
  )
}
