'use client'

import { PropsWithChildren, useEffect } from 'react'
import { EmblaOptionsType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import { twMerge } from 'tailwind-merge'

import { ShowcaseContext } from './ShowcaseContext'

interface ShowcaseCarouselProps extends EmblaOptionsType {
  className?: string
  active?: boolean
  options: EmblaOptionsType
}

export function ShowcaseCarousel({ children, className, active, options }: PropsWithChildren<ShowcaseCarouselProps>) {
  const [
    {
      value,
      context: { items },
    },
    sendToShowcase,
  ] = ShowcaseContext.useActor()

  const [ref, api] = useEmblaCarousel(options)

  useEffect(() => {
    function selectHandler() {
      const index = api?.selectedScrollSnap() || 0
      sendToShowcase({ type: 'Go to slide', index })
    }

    if (api) {
      sendToShowcase({ type: 'Set initial state', api })
      api.on('select', selectHandler)
    }

    return () => {
      api?.off('select', selectHandler)
    }
  }, [api, items, sendToShowcase, value])

  return (
    <div className={className} ref={ref}>
      <div className={twMerge('flex h-full items-center', active && 'pointer-events-auto')}>{children}</div>
    </div>
  )
}
