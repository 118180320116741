'use client'

import { PropsWithChildren } from 'react'
import { shallowEqual } from '@xstate/react'
import { twMerge } from 'tailwind-merge'

import { NeighbourhoodSummaryEntry } from '@/data/getNeighbourhoods'
import { cn } from '@/lib/utils'
import { BackLink } from '../BackLink'
import { GlobalAppContext } from '../GlobalAppContext'
import { NavButton } from '../ModalGallery/NavButton'
import { NeighbourhoodSlides } from '../NeighbourhoodSlides'
import { ShowcaseCarousel } from '../Showcase/ShowcaseCarousel'
import { ShowcaseContext } from '../Showcase/ShowcaseContext'
import { ShowcaseProgress } from '../Showcase/ShowcaseProgress'

interface NeighbourhoodBrowserProps {
  neighbourhoods: NeighbourhoodSummaryEntry[]
}

export function NeighbourhoodCarousel({ neighbourhoods }: PropsWithChildren<NeighbourhoodBrowserProps>) {
  const [, sendToGlobalApp] = GlobalAppContext.useActor()
  const [, sendToShowcase] = ShowcaseContext.useActor()
  const { active, index, options, items, standalone, prevDisabled, nextDisabled } = ShowcaseContext.useSelector(
    (state) => {
      const { index, options, items, standalone } = state.context
      const active = state.matches('Active')
      const prevDisabled = index === 0
      const nextDisabled = index === items - 1
      return { active, index, options, items, standalone, prevDisabled, nextDisabled }
    },
    shallowEqual,
  )

  const handleSelect = (index: number) => {
    sendToShowcase({ type: 'Go to slide', index })
    if (!standalone) {
      sendToShowcase({ type: 'Activate showcase' })
      sendToGlobalApp({ type: 'Hide header bar' })
    }
  }

  const handleClickBack = () => {
    sendToShowcase({ type: 'Deactivate showcase' })
    sendToGlobalApp({ type: 'Show header bar' })
  }

  const handleClickPrevious = () => {
    if (active) {
      sendToShowcase({ type: 'Go to slide', index: Math.max(0, index - 1) })
    }
    if (!standalone) {
      sendToShowcase({ type: 'Activate showcase' })
      sendToGlobalApp({ type: 'Hide header bar' })
    }
  }
  const handleClicknext = () => {
    if (active) {
      sendToShowcase({ type: 'Go to slide', index: Math.min(items - 1, index + 1) })
    }
    if (!standalone) {
      sendToShowcase({ type: 'Activate showcase' })
      sendToGlobalApp({ type: 'Hide header bar' })
    }
  }
  return (
    <>
      {!standalone && (
        <div
          className={twMerge(
            'absolute right-6 top-5 z-50 text-3xl drop-shadow-lg transition-[opacity,left,transform] delay-100 duration-300 ease-in-out md:right-auto md:top-9 md:delay-0 md:duration-700',
            active ? 'scale-105 md:left-10' : 'pointer-events-none opacity-0 scale-0 md:-left-[50vw]',
          )}
        >
          <BackLink collapse underline onClick={handleClickBack} className="hidden md:block" />
          <button onClick={handleClickBack} className="drop-shadow-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-8 w-8 drop-shadow-lg md:hidden"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      )}

      <ShowcaseProgress
        className="pointer-events-auto absolute inset-x-6 bottom-0 z-10 h-10 md:bottom-5"
        currentIndex={index}
        length={items}
        onClick={handleSelect}
      />
      <ShowcaseCarousel
        active={active}
        options={options}
        className={twMerge(
          'pointer-events-none absolute h-full w-full flex-1 overflow-hidden',
          active && 'backdrop-blur backdrop-brightness-50',
        )}
      >
        <NeighbourhoodSlides neighbourhoods={neighbourhoods} />
      </ShowcaseCarousel>
      <div className="pointer-events-auto z-10 opacity-100 delay-1000 duration-500">
        <NavButton
          className={cn(
            'absolute left-0 top-1/2 translate-x-full lg:left-1/12 lg:-translate-x-full',
            !active && 'aria-disabled:opacity-0',
          )}
          direction="prev"
          disabled={prevDisabled}
          onClick={handleClickPrevious}
        />
        <NavButton
          className={cn(
            'absolute right-0 top-1/2 -translate-x-full lg:right-1/12 lg:translate-x-full',
            !active && 'aria-disabled:opacity-0',
          )}
          direction="next"
          disabled={nextDisabled}
          onClick={handleClicknext}
        />
      </div>
    </>
  )
}
